<script setup>
import Button from '@/Components/Button/Button.vue';
import FormSubject from '@/Components/Form/FormSubject.vue';
import LocationLayout from '@/Layouts/LocationLayout.vue';
import TeamMembers from '@/Pages/Staff/Locations/Partials/TeamMembers.vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const dayjs = inject('dayjs');
defineProps({
    location: Object,
});

const form = useForm({
    date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
    place_of_qr_code: {
        nl: '',
        en: '',
    },
});

const sendForm = () => {
    window.open(route('location.management.download-qr', form.data()), '_blank');
};
</script>

<template>
    <LocationLayout :title="$t('Management')">
        <FormSubject :title="$t('QR Code')">
            <FormKit
                type="date"
                name="valid_until"
                :label="$t('Valid until')"
                v-model="form.date"
                :errors="form.errors.date"
                :value="form.date"
            />

            <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
                <FormKit
                    type="text"
                    name="place_of_qr_code_nl"
                    :label="$t('Place of QR code {locale}', { locale: 'NL' })"
                    v-model="form.place_of_qr_code.nl"
                    :errors="form.errors.place_of_qr_code?.nl"
                    :value="form.place_of_qr_code.nl"
                />
                <FormKit
                    type="text"
                    name="place_of_qr_code_en"
                    :label="$t('Place of QR code {locale}', { locale: 'EN' })"
                    v-model="form.place_of_qr_code.en"
                    :errors="form.errors.place_of_qr_code?.en"
                    :value="form.place_of_qr_code.en"
                />
            </div>

            <div class="flex justify-end">
                <Button
                    type="button"
                    @click="sendForm"
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Download QR PDF')"
                />
            </div>
        </FormSubject>

        <FormSubject>
            <TeamMembers :team="location.team" :teamable="location" />
        </FormSubject>
    </LocationLayout>
</template>
